import React from "react";
import "./Hero.scss";
import Zoom from "react-reveal/Zoom";
import Fade from "react-reveal/Fade";

// assets
import me from "../../assets/me.svg";
import LinkedIn from "../../assets/LinkedIn.svg";
import Github from "../../assets/Github.svg";
import FaceBook from "../../assets/FaceBook.svg";
import Mail from "../../assets/Mail.svg";

const Hero = () => {
  return (
    <div className="Hero">
      <Fade left cascade>
        <div className="text">
          <h2>Hey, I'm</h2>
          <h1>Jonas Lillebø Haugen</h1>
          <h2>I make websites</h2>
        </div>
      </Fade>
      <Zoom>
        <div className="imgContainer">
          <img src={me} alt="Jonas Haugen" draggable="false" />
        </div>
      </Zoom>
      <Fade right>
        <div className="social">
          <div>
            <a
              href="https://www.linkedin.com/in/jonas-lilleb%C3%B8-haugen-6a442222a/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={LinkedIn} alt="LinkedIn" draggable="false" id="img1" />
            </a>
          </div>
          <div>
            <a
              href="https://github.com/Jonalh"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Github} alt="Github" draggable="false" id="img1" />
            </a>
          </div>
          <div>
            <a
              href="https://www.facebook.com/profile.php?id=100003069102416"
              target="_blank"
              rel="noreferrer"
            >
              <img src={FaceBook} alt="Facebook" draggable="false" id="img1" />
            </a>
          </div>
          <div>
            <a
              href="mailto:lillebohaugen@gmail.com"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Mail} alt="Email" draggable="false" id="img1" />
            </a>
          </div>
        </div>
      </Fade>
    </div>
  );
};

export default Hero;
