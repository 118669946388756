import React from "react";
import "./Project_reverse.scss";
import Fade from "react-reveal/Fade";

const ProjectReverse = (props) => {
  return (
    <div className="Project_reverse">
      <Fade left cascade>
        <div className="desc">
          <div className="title">
            <h3>{props.title}</h3>
          </div>
          <div className="technologies">
            {props.technologies.map((item) => (
              <div className="technology" key={item}>
                {item}
              </div>
            ))}
          </div>
          <div className="text">
            <p>{props.text}</p>
          </div>
        </div>
        <Fade up>
          <img src={props.img} alt="Project" />
        </Fade>
      </Fade>
    </div>
  );
};

export default ProjectReverse;
