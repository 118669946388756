import React from "react";
import "./AboutMe.scss";
import Fade from "react-reveal/Fade";

import me from "../../assets/me.jpg";

const AboutMe = () => {
  return (
    <div className="AboutMe">
      <Fade left cascade>
        <h2>Who am I?</h2>
      </Fade>
      <div className="container">
        <Fade left>
          <div className="imgContainer">
            <img src={me} alt="Jonas Haugen" />
          </div>
        </Fade>
        <Fade right>
          <div className="text">
            <p>
              Hi! My name is Jonas, and I come from Oppegård, a small town just
              south of Oslo. Currently, I am in my final year as a Web Developer
              student at NTNU in Gjøvik.
            </p>
            <p>
              I have always had a great interest in technology, and in recent
              years, I have also developed a growing interest in design. That's
              why I chose to apply for web development, as I believe the program
              offers a perfect combination of these two fields. Being able to
              see continuous results of what I develop is very rewarding, and
              it's what drives my motivation to work as a developer. I hope to
              use what I have learned in these three years to create innovative
              solutions and contribute to improving the user experience online.
            </p>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default AboutMe;
