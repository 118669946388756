import React, { useState } from "react";
import "./Contact.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emailjs from "emailjs-com";
import Fade from "react-reveal/Fade";

const emailjsUserId = "ezR1U8iyDD0iJvujC";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    text: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const isEmailValid = (email) => {
    // A basic regex to check if the email is valid
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(email);
  };

  const isFormValid = () => {
    const { name, email, text } = formData;
    const errors = [];
    if (name === "") {
      errors.push("Name is required");
    }
    if (email === "") {
      errors.push("Email is required");
    } else if (!isEmailValid(email)) {
      errors.push("Email is invalid");
    }
    if (text === "") {
      errors.push("Message is required");
    }
    if (errors.length > 0) {
      toast.error(errors[0], { autoClose: 3000 });
      return false;
    } else {
      toast.info("Sending...", { autoClose: 1000 });

      // Send the email using EmailJS
      emailjs
        .send(
          "service_5q62i6j", // Replace with your actual service ID
          "template_0p4ji18", // Replace with your actual template ID
          formData,
          emailjsUserId
        )
        .then(
          () => {
            toast.success("Message sent!", { autoClose: 3000 });
            setFormData({ name: "", email: "", text: "" });
          },
          (error) => {
            toast.error("Message failed to send: " + error.text, {
              autoClose: 3000,
            });
          }
        );

      return false;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    isFormValid();
  };

  return (
    <div className="Contact">
      <Fade left cascade>
        <h2>Send me an email</h2>
      </Fade>
      <Fade left cascade>
        <div className="container">
          <ToastContainer />
          <form onSubmit={handleSubmit} noValidate>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
            <label htmlFor="text">Your message</label>
            <textarea
              name="text"
              cols="30"
              rows="10"
              value={formData.text}
              onChange={handleChange}
              required
            ></textarea>
            <input type="submit" value="Send" id="submit" />
          </form>
        </div>
      </Fade>
    </div>
  );
};

export default Contact;
