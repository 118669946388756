import React from "react";
import "./Projects.scss";
import Fade from "react-reveal/Fade";

// components
import Project from "../Project/Project";
import ProjectReverse from "../Project_reverse/Project_reverse";

// images
import Home from "../../assets/Helply/Home.png";
import Bsafer from "../../assets/Bsafer/Bsafer.png";
import Bachelors from "../../assets/Bachelors/Bachelors.png";

const Projects = () => {
  return (
    <div className="Projects">
      <Fade left cascade>
        <h2>My work</h2>
      </Fade>
      <Project
        img={Bachelors}
        title="Bachelor Project"
        technologies={["MongoDB", "Express", "React", "Node"]}
        text="This is our most significant and final project to date. Our bachelor's group was assigned the responsibility of creating a website to convey crucial information about premature babies to parents. We have collaborated with Oslo University Hospital as our client for this project. They sought an interactive, user-friendly website that delivers reliable information to parents during vulnerable times. The solution was built using the MERN stack, with my primary contributions focusing on front-end design and development using React and SASS."
      ></Project>
      <ProjectReverse
        img={Bsafer}
        title="Bsafer"
        technologies={["Raspberry Pi", "Sense Hat", "MQTT"]}
        text="In this course, our team devised a system intended to alert parents when temperatures inside a vehicle reach unsafe levels, whether due to significant increases or decreases. To accomplish this, we designed a prototype for a child-specific smart car seat. We integrated a Raspberry Pi and a Sense HAT, which was attached to the seat, for efficient temperature fluctuation monitoring. In the event of extreme temperature shifts, an MQTT broker is responsible for transmitting notifications to the parents' mobile devices. My involvement in this project encompassed working with the Raspberry Pi, Sense HAT, and MQTT broker."
      ></ProjectReverse>
      <Project
        img={Home}
        title="Helply"
        technologies={["MongoDB", "Express", "React", "Node"]}
        text="Our team created a platform to simplify the task of locating second examiners for universities as a component of the Web Project course. The platform was constructed using the MERN stack, encompassing MongoDB, Express, React, and Node. My primary role in this project was focused on front-end development using React and SASS, as the course promoted the investigation and adoption of cutting-edge technologies."
      ></Project>
    </div>
  );
};

export default Projects;
